//rpt/duplicated-pos

import moment from "moment"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Pre } from "../../../shared/components/UIElements/Dumbs"
import sendRequest from "../../../shared/sendRequest"


const DuplicatedPOs = () => {
    const [ports, setRpts] = useState([])
    
    const getReports = async() => {
        const {rpt} = await sendRequest('GET','rpt/duplicated-pos');
        setRpts(rpt)
    }

    useEffect( () => {
        getReports()
    },[])
    return <div className="page">
        <header>
            <h3>Report: Duplcated POs</h3>
        </header>
        <main className="scrollable">
            

            <table className="card">
                <thead>
                    <tr>
                        <th>Assignation</th>
                        <th>Kind</th>
                        <th>Cur</th>
                        <th>Prev</th>
                        <th>Created</th>
                    </tr>
                </thead>
                <tbody>
                {ports.map( report => <tr key={report.id}>
                  <td>{report.assignation && moment.utc(report.assignation).format('DD/MMM/Y')}</td>
                    <td>{report.kind}</td>
                    <td><Pre value={report.cur} /></td>
                    <td><Pre value={report.prev} /></td>
                    <td>{report.createdAt && moment(report.createdAt).format('DD/MMM/Y')}</td>
                </tr>)}
                </tbody>
            </table>
        </main>
    </div>
}

export default DuplicatedPOs