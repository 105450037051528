import { Route, Routes } from "react-router-dom"
import DuplicatedPOs from "./pages/DuplicatedPOs"


const RptDuplicatedPOsRoutes = () => {
    return <Routes>
        <Route path="/rpt/duplicated-pos" element={<DuplicatedPOs />}/>


    </Routes>
}
export default RptDuplicatedPOsRoutes