import { Route, Routes } from "react-router-dom"
import Filters from "./pages/Filters"
import Filter from "./pages/Filter"
import POPlacements from "./pages/POPlacements"
import POPlacement from "./pages/POPlacement"

const POPRoutes = () => {
    return <Routes>
        <Route path="/po-placements/filter/:id" element={<Filter />} />
        <Route path="/po-placements/filters" element={<Filters />} />
        <Route path="/po-placements" element={<POPlacements />}/>
        <Route path="/po-placement/:id" element={<POPlacement />}/>
    </Routes>
}
export default POPRoutes