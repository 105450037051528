
import moment from "moment";
import { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import sendRequest from "../../shared/sendRequest";

const POPlacement = () => {
    const {id} = useParams()

    const navigate = useNavigate();

    const [port, setPOP] = useState({
        name:'',
        assignation:''
    })

    const updatePOP = async e =>{
        e.preventDefault()
        await sendRequest('PUT','po-placements/' + id,port)
        navigate('/po-placements')
    }


    const getPort = useCallback(async() => {
        if(!id) return false;
        const {pop} = await sendRequest('GET','po-placements/' + id);
        pop.assignation = pop.assignation && moment.utc(pop.assignation).format('Y-MM-DD')
        setPOP(pop)
    },[id])

    useEffect( () => {
        getPort()
    },[getPort])

    return <div className="page">
        <header>
            <h3>Edit PO Placement</h3>
            <Link to="/po-placements"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            <form onSubmit={updatePOP} className="card">
                <label>
                    Name
                    <input placeholder="Name" value={port.name} onChange={ e => setPOP( state => ({...state, name:e.target.value}))} />
                </label>
                <label>
                    Date
                    <input placeholder="Date" type="date" value={port.assignation} onChange={ e => setPOP( state => ({...state, assignation:e.target.value}))} />
                </label>
                
                
                <button type="submit">Update PO</button>
            </form>

    


        </main>
    </div>
}

export default POPlacement;